export default {
  fonts: {
    body: '"Univers", sans-serif',
    heading: '"Univers", sans-serif'
  },
  text: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '.15em',
    }
  },
  input: {
    fontFamily: 'body'
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'black',
      cursor: 'pointer',
      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
    },
    secondary: {
      color: 'white',
      bg: 'green',
      cursor: 'pointer',
      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
    }
  },
  colors: {
    background: 'black',
    black: '#000',
    white: '#FFF',
    primary: '#E5B745',
    secondary: '#D43034',
    green: '#16703b'
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
    },
  }
}
