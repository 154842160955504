import React, { useState, useEffect } from "react"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"

import SEO from "../components/seo"
import { Helmet } from 'react-helmet'

import {
  ThemeProvider,
  Heading,
  Grid,
  Box,
  Text,
  Input,
  Textarea,
  Label,
  Button,
  Link
} from 'theme-ui'

import QRCode from 'qrcode.react'

import theme from "../theme"

import "../components/fonts.css"

function IndexPage() {
  const [urlAmount] = useQueryParam("amount", NumberParam);
  const [urlRecipients] = useQueryParam("recipients", StringParam);
  const [urlNote] = useQueryParam("note", StringParam);

  const baseLink = "venmo://paycharge?txn=pay&recipients=";
  const baseLocalLink = "https://venmo.thrdhnd.com/?recipients="

  const [recipients, setRecipients] = useState("");
  const [amount, setAmount] = useState(10);
  const [note, setNote] = useState("%23PAYTHEFEE");

  useEffect(() => {
    resetCopyButtonText()
  }, [recipients, amount, note])

  useEffect(() => {
    if (urlAmount && urlRecipients && urlNote) {
      window.location.href = baseLink + urlRecipients.replace(/\s/g, '').replace(/@/g, '') + "&amount=" + urlAmount + "&note=" + encodeURIComponent(decodeURIComponent(urlNote));
    }
  })

  const downloadQR = () => {
    var canvas = document.getElementsByTagName('canvas')[0];
    var node = document.getElementById('download_url');

    if (canvas) {
      node.href = canvas.toDataURL('image/png');
      node.download = `${recipients.replace(/\s/g, '').split(',').join('-')}-$${amount}.png`;
      node.click();
    }
  }

  const copyLink = () => {
    var linkInput = document.getElementById('Venmo-link');

    if (linkInput) {
      linkInput.select();
      linkInput.setSelectionRange(0, 99999);

      document.execCommand("copy");
      document.getSelection().removeAllRanges();

      linkInput.blur();

      document.getElementById('btn__copy--text').textContent = "Copied!"
    }
  }

  const testLink = () => {
    window.open(baseLink + recipients.replace(/\s/g, '').replace(/@/g, '') + "&amount=" + amount + "&note=" + note);
  }

  const resetCopyButtonText = () => {
    document.getElementById('btn__copy--text').textContent = "Copy link"
  }

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{
      px: [3, null, 6]
    }}>
      <SEO title="#PAYTHEFEE Venmo Link Generator" />
      <Helmet>
        <script type="text/javascript">{`
          window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load("1788381491");`}
        </script>
      </Helmet>
      <Grid
        pt={4}
        sx={{
        justifyContent: 'center'
      }}>
        <Box>
          <Heading variant='caps' sx={{
            color: 'white',
            fontSize: [5, null, 6]
          }}>#PayTheFee</Heading>
        </Box>
      </Grid>
      <Grid sx={{
        justifyContent: 'center'
      }}>
        <Box>
          <Heading
            variant='caps'
            pb={5}
            sx={{
              color: 'white',
              fontWeight: 1,
              fontSize: [4, null, 5],
              textAlign: 'center'
          }}>Venmo Link Generator</Heading>
        </Box>
      </Grid>
      <Grid
        gap={2}
        columns={[1, null, 2]}
        bg='primary'
        sx={{
          maxWidth: 768,
          mx: 'auto',
          p: 4,
          borderRadius: '5px',
          border: '1px solid transparent'
        }}>
        <Box mb={3}>
          <Box mb={4}>
            <Label
              mb={1}
              sx={{
                fontFamily: 'heading',
                color: 'secondary',
                textTransform: 'uppercase',
                fontSize: 3
            }}>Venmo username(s)</Label>
            <Text mb={3} sx={{
              fontSize: '1'
            }}>Separate multiple users with a comma.</Text>
            <Input sx={{width: ['100%', '75%']}} name='Venmo-Username' value={recipients} bg='white' radii={5} onChange={e => setRecipients(e.target.value)} placeholder='Pay-The-Fee' />
          </Box>

          <Box mb={4}>
            <Label
              mb={1}
              sx={{
                fontFamily: 'heading',
                color: 'secondary',
                fontSize: 3,
                textTransform: 'uppercase'
            }}>Amount ($)</Label>
    <Input sx={{ width: ['100%', '50%'] }} name='amount' value={"$" + amount} bg='white' radii={5} onChange={e => setAmount(e.target.value.replace("$", ""))} placeholder='10' />
          </Box>
          <Box>
            <Label
              mb={1}
              sx={{
                fontFamily: 'heading',
                color: 'secondary',
                fontSize: 3,
                textTransform: 'uppercase'
            }}>Note</Label>
            <Textarea sx={{ fontFamily: 'body', fontSize: 2, width: ['100%', '75%'] }} name='note' value={decodeURIComponent(note)} bg='white' radii={5} onChange={e => setNote(encodeURIComponent(e.target.value))} placeholder='#PAYTHEFEE' />
          </Box>
        </Box>
        <Box>
          <Grid
            gap={1}
            columns={1}
            sx={{
              justifyContent: 'center',
              mt: [3, 0],
              textAlign: ['center', null, 'left']
            }}>
            <Text mb={1} sx={{
              color: 'secondary',
              fontSize: '3',
              textTransform: 'uppercase'
            }}>
              QR Code
              <Text mb={2} sx={{
                fontSize: 1,
              }}>(Posters, FB events)</Text>
            </Text>
            <Text>
              <QRCode value={baseLink + recipients.replace(/\s/g, '').replace(/@/g, '') + "&amount=" + amount + "&note=" + note} />
            </Text>
            <Grid gap={4} columns={[1, null, 2]}>
              <Button variant='primary' id='btn__download' onClick={downloadQR}><Text sx={{
                fontWeight: 'bold'
              }}>Download</Text></Button>
              <Link id='download_url' href="#" sx={{
                display: 'none'
              }}>Download QR</Link>
            </Grid>
            <Box mt={4}>
              <Text mb={1} sx={{
                color: 'secondary',
                fontSize: '3',
                textTransform: 'uppercase'
              }}>
                Link
                <Text mb={2} sx={{
                  fontSize: '1'
                }}>
                  (Social media, online, text message)
                </Text>
              </Text>
              <Input readOnly id='Venmo-link' value={baseLocalLink + recipients.replace(/\s/g, '').replace(/@/g, '') + "&amount=" + amount + "&note=" + encodeURIComponent(note)} bg='white' radii={5} placeholder='venmo://' />
              <Grid
                gap={[1, null, 4]}
                columns={[1, null, '1fr 1fr']}
                sx={{
                  justifyContent: 'center'
                }}>
                <Box>
                  <Button sx={{ width: '100%'}} variant='secondary' mt={1} id='btn__test-link' onClick={testLink}>
                    <Text
                      sx={{
                        fontWeight: 'bold'
                      }}>
                      Test link
                    </Text>
                  </Button>
                  <Text
                    sx={{
                      display: ['none', 'none', 'block'],
                      fontSize: 1
                    }}
                    color='secondary'
                    mt={2}>
                      (phones only)
                    </Text>
                </Box>
                <Box>
                  <Button sx={{ width: '100%'}} variant='primary' mt={[0, null, 1]} id='btn__copy' onClick={copyLink}>
                    <Text id='btn__copy--text' sx={{
                      fontWeight: 'bold'
                    }}>
                      Copy link
                    </Text>
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Grid sx={{textAlign: 'center', justifyContent: 'center'}}>
        <footer>
          <Text
            mt={4}
            color='white'
            sx={{
              fontFamily: 'heading',
              fontSize: 1
            }}>
            Built in
            <Text mx={1} sx={{display: 'inline-block', fontWeight: 'bold'}}>
              Seattle
            </Text>
            for
            <Text mx={1} sx={{display: 'inline-block', fontWeight: 'bold'}}>
              Seattle
            </Text>
          </Text>
          <Text
            sx={{
              fontFamily: 'heading',
              fontWeight: 'bold',
              fontSize: 1
            }}>
            © Third Hand {new Date().getFullYear()}
          </Text>
        </footer>
      </Grid>
    </Box>
  </ThemeProvider>
  )
}

export default IndexPage
